import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"
import { getEBooksCoverUrl } from "utils/rsc-util"

const eBooks = [
  {
    title: "React Hooks Nedir?",
    url: "https://learnreactui.dev/contents/react-hooks-nedir",
    img: getEBooksCoverUrl("006_react-hooks-nedir"),
  },
  {
    title: "What are React Hooks?",
    url: "https://learnreactui.dev/contents/what-are-react-hooks",
    img: getEBooksCoverUrl("007_what-is-react-hooks"),
  },
]

const LinkMap = {
  "React Hooks Examples": "f3a3b6027f71",
  "What React Hooks": "37140f07e9f9",
  "Why Hooks": "8d54e96fd6ea",
  useState: "9b7ae42a6895",
  useEffect: "1a94e5463b7f",
  "useEffect Sample": "2b72f4470ff7",
  useContext: "ae3e824fe414",
  useReducer: "cb19c7fa0746",
  "calculator hook usage": "30194e8338eb",
  "useCallback usage": "e9957e672c3e",
  useMemo: "6f52578e3fa0",

  useRef: "435768d045b3",
  useImperativeHandle: "435768d045b3",
  forwardRef: "435768d045b3",

  useLayoutEffect: "48bbba5368ec",
  useDebugValue: "7a3346563305",

  useTimeout: "1245cccde809",
  useInterval: "1245cccde809",
}

const storiesHooks = [
  {
    title: "RH-01 React Hooks Nedir?",
    postId: "37140f07e9f9",
  },
  {
    title: "RH-02 React Hooks Neden Çıktı ?",
    postId: "8d54e96fd6ea",
  },
  {
    title: "RH-03 useState Nedir ?",
    postId: "9b7ae42a6895",
  },
  {
    title: "RH-04 useEffect Nedir ?",
    postId: "1a94e5463b7f",
  },
  {
    title: "RH-05 useEffect Örnek Analiz",
    postId: "2b72f4470ff7",
  },
  {
    title: "RH-06 useContext Nedir ?",
    postId: "ae3e824fe414",
  },
  {
    title: "RH-07 useReducer Nedir ?",
    postId: "cb19c7fa0746",
  },
  {
    title: "RH-08 useCallback ?",
    postId: "e9957e672c3e",
  },
  {
    title: "RH-09 useMemo",
    postId: "6f52578e3fa0",
  },

  {
    title: "RH-10 useRef ve useImperativeHandle",
    postId: "435768d045b3",
  },
  {
    title: "RH-11 useLayoutEffect",
    postId: "48bbba5368ec",
  },
  {
    title: "RH-12 useDebugValue",
    postId: "7a3346563305",
  },
]

const storiesCustomHooks = [
  {
    title: "useTimeout and useInterval",
    postId: "1245cccde809",
  },
]

const storiesUseHooks = [
  {
    title: "extends useState",
    postId: "87bd07219feb",
  },
  {
    title: "extends Event Handler",
    postId: "a36a0b38cf14",
  },
  {
    title: "extends DOM API",
    postId: "8963f655292e",
  },
  {
    title: "extends Timer & Interval",
    postId: "8018322ee2eb",
  },
  {
    title: "extends Web API",
    postId: "ab44a2c96144",
  },
  {
    title: "extends Platform",
    postId: "84abd272310e",
  },
]

const storiesGroup = [
  {
    title: "Hooks",
    storyPart: storiesHooks,
  },
  {
    title: "Custom Hooks",
    storyPart: storiesCustomHooks,
  },
  {
    title: "UseHooks Samples",
    storyPart: storiesUseHooks,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "React Hooks",
  path: "react-hooks",
}

const ReactHooksPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
      eBooks={eBooks}
    />
  )
}

export default ReactHooksPage
